import React from 'react';
import ReactDOM from 'react-dom';

import {
  Badge,
  Row,Col,
  Table,
  Card,
  Button
} from 'reactstrap';

import {
  withRouter,
  NavLink,
  Link,
} from 'react-router-dom';

import AGALeaderboardPage from 'pages/user/AGALeaderboard.js';

import 'pages/user/Events.css';
import EventRulesModal from 'pages/user/EventRulesModal';
import RenderBannerAds from 'common/RenderBannerAds.js';
import {EventTitle} from 'common/EventTitle.js';
import {shadeColor} from 'utils/constants.js';

import {
  get_aga_league_events,
} from 'utils/api.js';

class AGAEventsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      events: [],
      eventid: '',
    };

  }

  componentDidMount() {
    const leaguegrpid = this.props.match.params.leaguegrp;
    this.fetchLeagueEvents(leaguegrpid);
  }

  componentWillReceiveProps(nextProps) {

  }

  fetchLeagueEvents = (leaguegrpid) => {

    get_aga_league_events(leaguegrpid,
      ({success, error, leagueGrp, leagues, events}) => {
        this.setState({ loading: false });

        /*if (!success) {
          console.log(error);
          this.props.history.push("/");
          return;
        }*/

        let eventid = this.props.match.params.event;
        if (eventid && events.filter((e)=>{return e.eventid === (parseInt(eventid)||0);}).length > 0){
          eventid = parseInt(eventid);
        }else if (events.length >0){
          eventid = events[0].eventid;
        }
        this.setState({leagueGrp, leagues, events, eventid});

      },
      (error) => {
        console.log('error getting leaderboard: ' + error);
        this.setState({eventid:0, loading: false});
      }
    );
  }

  getMatchingEvent = () => {
    const eventid = this.state.eventid;
    if (isNaN(eventid)) {
      return null;
    }
    for (var e of this.state.events) {
      if (e.eventid === eventid) {
        return e;
      }
    }
    return null;
  }

  selectEventId = (eventid) =>{
    this.setState({eventid});
  }


  render() {
    if (this.state.loading) {
      return null;
    }

    const event = this.getMatchingEvent();
    let content;


    if (!event) {
      content = <EventNotFound />
    } else if (event.status === "open") {
      content = ''
    } else if (event.status === "scheduled") {
      let eventRules = <span style={{fontSize: "1.25rem"}}>Event Rules</span>
      let prize = <span style={{fontSize: "1.25rem"}}>Prizes</span>
      content = <div className="d-flex flex-column justify-content-center text-center">
        <EventTitle event={event} className="text-center mt-4"/>
        <span>
          <EventRulesModal activeLeague={this.state.activeLeague} event={event} component={eventRules} />
          {event.prizePayout &&
            <>
            <span className="mx-2">&bull;</span>
            <EventRulesModal activeLeague={this.state.activeLeague} event={event} component={prize} />
            </>
          }
          {event.perfect_team &&
            <span style={{fontSize: "1.25rem"}}>
              <span className="mx-2">&bull;</span>
              <a target="_blank" href="/perfectteam">Perfect Team</a>
            </span>
          }
          </span>
        <span className="mb-4" style={{color: "#999"}}>(subject to change)</span>


        {(!event.toc || !event.isTocFinalEvent) &&
        <h5 className="text-center mx-4">This event has been scheduled, but the golfer field has yet to be finalized.</h5>
        }


        <Row noGutters className="pt-2 d-flex justify-content-center">
          <Col lg="7" className="my-1">
            <RenderBannerAds configGrp={'RENDER_ALL_BANNER'} leagueId={this.state.activeLeague} place={'EVENT'}/>
          </Col>
        </Row>



      </div>
    } else {

      content = <AGALeaderboardPage leagueGrp={this.state.leagueGrp} event={event}
                history={this.props.history}/>

    }

    const pleagueName = this.props.location && this.props.location.state? this.props.location.state.leagueName : '';
    const pleagueEventId = this.props.location && this.props.location.state? this.props.location.state.legueEventid : '';

    return (
      <>

      <div className="eventsWrapper">
        {!this.props.match.params.event &&
          <EventsHeader events={this.state.events} eventid={this.state.eventid} selectEventId={this.selectEventId}/>
        }
        <div className="eventsContainer">
          <div className="leaderboardContainer container">
          <Row noGutters className="pt-2 mx-lg-2 d-flex justify-content-left">
              <Col className="mr-2 px-lg-3 d-flex justify-content-end" style={{maxWidth: 768}}>
              <Link to={{
                  pathname: "/events/"+ pleagueEventId
              }}> {'<< '}{pleagueName} Leaderboard</Link>
              </Col>
          </Row>
          </div>
          {content}
        </div>
      </div>
      </>
    )
  }
}

const EventNotMemberOf = (props) => (
  <Row noGutters className="pt-2 d-flex justify-content-center">
    <Col lg="7" className="my-1">
      <center>
        <h3>Sorry, you are not a member of this league.<br/><br/> Please check which user you are logged in as.</h3>

        <div className="py-2 d-flex" style={{width:'200px'}}>
          <Button className="" color="primary" size="md"
            onClick={()=>props.history.push('/')} block> {'Dashboard'} </Button>

          <Button className=" ml-3" color="primary" size="md"
          onClick={()=>props.history.push('/profile/settings')} > {'Profile'} </Button>

        </div>
      </center>
    </Col>
  </Row>

)

const EventNotFound = (props) => (
  <center>
    <h2>{'The event you were looking for could not be found.'}</h2>
  </center>
)

class EventsHeaderImpl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSeason: null,
      seasons: {},
    };

    this.updateSeason = this.updateSeason.bind(this);
    this.pickEvent = this.pickEvent.bind(this);
  }

  componentDidMount() {
    this.updateSeason(this.props.eventid, this.props.events);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.eventid === nextProps.eventid){return;}
    this.updateSeason(nextProps.eventid, nextProps.events);
  }

  updateSeason(eventId, events) {
    const event = events.find(({eventid}) => eventid === eventId);
    if (event === undefined) { return; }

    const currentSeason = event.season;

    const seasons = {};
    events.forEach((evt) => {
      if (!(evt.season in seasons)) { seasons[evt.season] = []; }
      seasons[evt.season].push(evt);
    })

    this.setState({
      currentSeason,
      seasons,
    });
  }

  pickEvent({eventid}) {
    this.props.selectEventId(eventid);
    //this.props.history.push('/events/'+eventid);
  }

  render() {
    const events = [];

    Object.keys(this.state.seasons).sort().forEach((season) => {
      if (parseInt(season, 10) !== this.state.currentSeason) {
        events.push(
          <span className="eventsHeader_item" key={'season'+season} onClick={() => {this.pickEvent(this.state.seasons[season][0])}}>
            <li className="eventsHeader_itemcell">{season}</li>
          </span>
        );
      } else {
        const sEvents = this.state.seasons[season];
        sEvents.forEach((evt) => {
          events.push(<EventsHeaderItem key={evt.eventid} {...evt} selectedEventId={this.props.eventid} selectEventId={this.props.selectEventId} history={this.props.history}/>)
        })
      }
    });

    const selectedLeague = this.props.selectedLeague;
    const eventRibbonColor = (selectedLeague && selectedLeague.whiteLabel && selectedLeague.whiteLabel.branding.topBannerColor)?shadeColor(selectedLeague.whiteLabel.branding.topBannerColor,-30):'#2c3e50';


    return (
      <div className="eventsHeaderContainer" style={{backgroundColor:eventRibbonColor}}>
        <ul className="eventsHeader">
          {events}
        </ul>
      </div>
    )
  }
}

const EventsHeader = withRouter(EventsHeaderImpl);

class EventsHeaderItem extends React.Component {
  constructor(props) {
    super(props);
    this.scrollIntoView = this.scrollIntoView.bind(this);
    this.makePath = this.makePath.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
  }
  componentDidMount() {
    //const path = this.makePath();
    //if (path === this.props.history.location.pathname) {
      //this.scrollIntoView();
    //}
    if (this.props.selectedEventId === this.props.eventid){
      this.scrollIntoView(this.props.eventid);
    }
    window.addEventListener('mouseup', this.handleMouseUp.bind(this));
    window.addEventListener('mousemove', this.handleMouseMove.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener('mouseup', this.handleMouseUp.bind(this));
    window.removeEventListener('mousemove', this.handleMouseMove.bind(this));
  }
  makePath() {
    return '#';
  }
  async scrollIntoView(eventid) {
    this.props.selectEventId(eventid);
    let domNode = ReactDOM.findDOMNode(this);
    if (domNode) {
      // Unfortunately, scrollIntoView with the object param is not supported in all browsers
      // domNode.scrollIntoView({
      //   behavior: "smooth",
      //   block: "center",
      //   inline: "center"
      // })
      let start = domNode.parentNode.scrollLeft;
      let currentTime = 0;
      const increment = 17;
      const box = domNode.getBoundingClientRect();
      const diff = box.width/2 + box.x - window.innerWidth/2;
      const duration = 400; // milliseconds

      // http://easings.net/#easeInOutQuad
      const easeInOutQuad = (t, s, c, d) => {
        t /= d/2;
        if (t < 1) {
          return c/2 * t * t + s;
        }
        t--;
        return -c/2 * (t * (t - 2) - 1) + s;
      }
      let animateScroll = function() {
        currentTime += increment;
        domNode.parentNode.scrollLeft = easeInOutQuad(currentTime, start, diff, duration);;
        if (currentTime < duration) {
          setTimeout(animateScroll, increment);
        }
      }

      animateScroll();
    }
  }
  handleMouseUp(e) {
    if (this.dragging) {
      this.dragging = false;
    }
  }
  handleMouseDown(e) {
    if (!this.dragging) {
      this.dragging = true;
      this.prevClientX = e.clientX;
      e.preventDefault();
    }
  }
  handleMouseMove(e) {
    if (this.dragging) {
      let domNode = ReactDOM.findDOMNode(this);
      if (domNode !== null) {
        let parent = domNode.parentNode;
        const currClientX = e.clientX;
        parent.scrollLeft -= currClientX - this.prevClientX;
        this.prevClientX = currClientX;
      }
    }
  }
  render() {
    let badge;
    if (["round1", "round2", "round3", "round4"].indexOf(this.props.status) !== -1) {
      badge = <Badge color="primary" className="eventsHeader_itembadge">LIVE</Badge>
    } else if (this.props.status === "open") {
      badge = <Badge color="warning" className="eventsHeader_itembadge">OPEN</Badge>
    }

    const navLinkProps = {
      to: this.makePath(),
      activeClassName: "eventsHeader_item__active",
      className: (this.props.selectedEventId === this.props.eventid ? "eventsHeader_item eventsHeader_item__active" : "eventsHeader_item"),
      exact: true,
      onClick:()=>{this.scrollIntoView(this.props.eventid)},
      onMouseUp: this.handleMouseUp,
      onMouseDown: this.handleMouseDown
    }



    return (
      <NavLink {...navLinkProps}>
        <li className="eventsHeader_itemcell">{badge} {this.props.title}</li>
      </NavLink>
    )
  }
}



export default AGAEventsContainer;
